<div class="container">
  <!--logo-->
  <div class="py-4 text-left hr-border header-position">
     <!-- <img class="d-block mx-auto mb-3" src="../../assets/img/logo.png" alt="" > -->
     <div>
     <img class="d-block mx-auto mb-2" src="assets/img/logo.jpg" alt="" class="logo" >
     <div class="head_title">Thank you for taking the time to see our work.</div>
    </div>
     <div class="sign-out "><button class="btn btn-success" (click)="logout()">Sign out </button></div>
  </div>
  <!-- end logo-->
  <div class="row mt-12">
    <!--First Section-->
   
    <div class="col-sm-12 col-md-12 ">
      
     </div>

  </div>
  <!-- <div class="form-group">
    <input type="text" class="form-control" placeholder="Search Here" [(ngModel)]="term">
    </div> -->

  <!--check boxes-->
  <div class="row mt-5">
     <!--First Section-->
    
     <div class="col-sm-4 col-md-3 zero-padding">
        <h4 class="small-title">FORMAT: </h4>

        <!-- Default unchecked -->
      <div *ngFor="let item of checklist; let i= index" style="display: inline-block; width: 50%; vertical-align: top">
        <div class="customize-checkbox"  >
            <input type="checkbox" id="{{'testf'+ i}}" [(ngModel)]="item.isSelected" name="list_name" value="{{item.id}}" (change)="isAllSelected()"   />
            <label for="{{'testf'+ i}}">{{item.value}}</label>
       
        </div>
      </div>
     </div>
     <!--End Section-->
     <!--Second Section-->
     <div class="col-sm-5 col-md-3 zero-padding">
        <h4 class="small-title">STYLE: </h4>

        <!-- Default unchecked -->
      <div *ngFor="let item of checkliststyle; let i= index" style="display: inline-block; width: 50%; vertical-align: top" >
        <div class="customize-checkbox"  >
            <input type="checkbox" id="{{'tests'+ i}}" [(ngModel)]="item.isSelected" name="list_name" value="{{item.id}}" (change)="isAllSelected()"   />
            <label for="{{'tests'+ i}}">{{item.value}}</label>
       
        </div>
      </div>
     </div>
     <!-- End Second  Section-->
     <!--Third Section-->
     <div class="col-sm-5 col-md-3  zero-padding">
        <h4 class="small-title">TOPIC AREA: </h4>
        <!-- Default unchecked -->
      <div *ngFor="let item of checklisttopicarea; let i= index"  style="display: inline-block; width: 50%; vertical-align: top">
        <div class="customize-checkbox"  >
            <input type="checkbox" id="{{'testt'+ i}}" [(ngModel)]="item.isSelected" name="list_name" value="{{item.id}}" (change)="isAllSelected()"   />
            <label for="{{'testt'+ i}}">{{item.value}}</label>
        </div>
      </div>
     </div>
     <!--End Third  Section-->
     <!--Fourth Section-->
     <div class="col-sm-5 col-md-3 zero-padding">
        <h4 class="small-title">INDUSTRY: </h4>
        <!-- Default unchecked -->
      <div *ngFor="let item of checklistindustry; let i= index"  style="display: inline-block; width: 50%; vertical-align: top">
        <div class="customize-checkbox"  >
            <input type="checkbox" id="{{'testi'+ i}}" [(ngModel)]="item.isSelected" name="list_name" value="{{item.id}}" (change)="isAllSelected()"   />
            <label for="{{'testi'+ i}}">{{item.value}}</label>
        </div>
      </div>
     </div>
      
     <!--End FOURTH  Section-->
  </div>
  <!--end check boxes-->
  <div class="row mt-5 services-box">
      <div  class="col-sm-6 col-md-3 mb-3" *ngFor="let item of data |filter:term">
        <a href="{{item.url}}" target="_blank">
       
        <app-item 
          [name]="item.name"
          [Description]="item.Description"
          [tileimgname]="item.tileimgname" 
          [url]="item.url"  
          [colortile]="item.colortile">
        </app-item >
    
      </a>

     </div>
     
    </div>
  
  <!--footer-box-->
  <div class="row mt-4 footer hr-top-border">
    <div class="col-md-12">
     <p class="text-center">&copy; Edvantech.</p>
    </div>
  </div>
</div>