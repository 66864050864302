import { Component } from '@angular/core';
import { AuthenticationService } from '@/app/services'; 
import { Router } from '@angular/router';
import { User } from '../app/models/user';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Course Demo App';
  currentUser: User;

  constructor(private router: Router,
    private authenticationService: AuthenticationService) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  logout() {
        this.authenticationService.logout();
         
        location.reload();
       // this.router.navigate(['/login']);
    }

}
