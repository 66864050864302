<!-- <div class="video-background">
        <div class="video-foreground">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/_8G63nK-QGM?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&playlist=_8G63nK-QGM"
                    frameborder="0" allow="autoplay"
                    allowfullscreen></iframe>
                    
        </div>
        
      </div> -->
      <!-- <app-video-player></app-video-player> -->
      <div class="video-background">
        <div class="video-foreground">
          <div id="player"></div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6 col-sm-12 col-lg-6  pull-left ">
       <div class="home_text"> LEARNING EXPLORED</div>
       </div>

<div class="box col-md-6 col-xs-6  col-sm-12 col-lg-6 center pull-right outer-login-box  customers_login">
<div class="container">
  <div class="d-flex justify-content-center  justify-content-md-end   h-100 login-box  pull-md-7 ">
      <div class="card">
         
          <div class="card-body">
                 <div class="avatar"><img src="assets/img/avatar_img.png" alt=""></div>
              <form [formGroup]="loginForm" class="form-signin" (ngSubmit)="onSubmit()">
                  <div   class="errormsg">{{loginerror}}</div> 
                  <div class="form-group divider">
                     
                      <div class="row pt-2">
                          <label class="control-label col  pr-0" for="username">Username:</label>
                          <div class="col-sm-8">
                                  <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" >
                                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Username is required</div>
                                </div>
                          </div>
                      </div>
                  
                  </div>
                  <div class=" form-group divider">
                          <div class="row pt-2">
                                  <label class="control-label col pr-0" for="password">Password:</label>
                                  <div class="col-sm-8">
                                          <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" >
                                          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                  </div>

                              </div>
                     
                  </div>
                
                  <div class="form-group text-center divider pt-4">
                    <button [disabled]="loading"class="btn btn-secondary  grey-button">Sign in</button>
                       
                  </div>
              </form>
              <div class="d-flex justify-content-center pt-2 ">
                      <!-- <a href="#"> I can't remember my password</a> -->
                   </div>
          </div>
         
      </div>
  </div>
</div>
</div>
