import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable,throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    
    constructor(private http: HttpClient) {
       
        this.currentUserSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('currentUser')));
        var s = JSON.parse(sessionStorage.getItem('currentUser'));
                    
        
       // console.log(s.startdate);
        this.currentUser = this.currentUserSubject.asObservable();
       
        
    }



    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }



    
    error(message) {
        return throwError({ error: { message } });
    }

    login(username: string, password: string) {
        
        return this.http.get<User[]>('assets/user.json')
            .pipe(map(user => {
                
                // login successful if there's a jwt token in the response
                if(user.find(x=>x.username===username) &&  user.find(x=>x.password===password))
                {
                 var usr = user.find(x=>x.username===username);
                
                 if (usr!=undefined ) {
                     
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('currentUser', JSON.stringify(usr));
                    this.currentUserSubject.next(usr);
                 }
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
