import { Component, OnInit ,AfterViewInit} from '@angular/core';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { AuthenticationService  } from '@/app/services';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject,throwError } from 'rxjs';
import { first, } from 'rxjs/operators';
import { tap, map, filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
//import {User} from '@/app/models'
//import Datauser from '../models/user';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers:[DatePipe]
})
export class LoginComponent implements OnInit, AfterViewInit  {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  loginerror =  "";
  private usr:any;
  player;
  //private datauser = Datauser[];
  constructor(private dataService: DataService,
     private formBuilder: FormBuilder,
     private route: ActivatedRoute,
     private router: Router,
     private authenticationService: AuthenticationService,
     private datePipe: DatePipe  ) {
  
     
    if (this.authenticationService.currentUserValue) { 
      this.router.navigate(['/']);
  }

   }
   ngAfterViewInit() {
    
    const doc = (<any>window).document;
    const playerApiScript = doc.createElement('script');
    playerApiScript.type = 'text/javascript';
    playerApiScript.src = 'https://www.youtube.com/iframe_api';
    doc.body.appendChild(playerApiScript);
  }
  
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
      
  });
  
  (<any>window).onYouTubeIframeAPIReady = () => {
    this.player = new (<any>window).YT.Player('player', {
      height: '100%',
      width: '100%',
      videoId: this.getVideo(),
      events: {
        'onReady': (event) => { this.onPlayerReady(event); },
        'onStateChange': (event) => { this.onPlayerStateChange(event); }
      },
      playerVars: {
        mute: 1,
              autoplay: 1,
              loop: 1,
              controls: 0,
              showinfo: 0,
              autohide: 0,
              enablejsapi: 1,
              modestbranding: 1,
              playlist: '_8G63nK-QGM',
              vq: 'hd1080'
      }
    });
  };
  // debugger;
  // this.dataService.get_login().subscribe((data) => {
  //   this.usr = data; 
  //   console.log('ddddd' + data);
  //   });
  
}

  error(message) {
    this.loginerror = message ;
}

  get f() { return this.loginForm.controls; }
  onPlayerStateChange(event) {
    console.log('onPlayerStateChange');
    event.target.playVideo();
}

// The API will call this function when the video player is ready
onPlayerReady(event) {
  console.log('onPlayerReady');
  const videoId = this.getVideo();
  event.target.cueVideoById({
    'videoId': videoId
  });
  event.target.playVideo();
}

getVideo() {
  return '_8G63nK-QGM';
}

public  compareDate(date1: Date, date2: Date): number
{
  // With Date object we can compare dates them using the >, <, <= or >=.
  // The ==, !=, ===, and !== operators require to use date.getTime(),
  // so we need to create a new instance of Date with 'new Date()'
  let d1 = new Date(date1); let d2 = new Date(date2);

  // Check if the dates are equal
  // let same = d1.getTime() === d2.getTime();
  // if (same) return 0;

  // Check if the first is greater than second
  if (d1 > d2) return 1;
 
  // Check if the first is less than second
  if (d1 < d2) return -1;
}

  onSubmit() {
        
    this.submitted = true;
    // stop here if form is invalid
   
    if (this.loginForm.invalid) {
        return;
    }


    
    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
         
            data => {
              if(data.find(x=>x.username===this.f.username.value) &&  data.find(x=>x.password===this.f.password.value))
              {
               var usr = data.find(x=>x.username===this.f.username.value);
               
               let today = new Date();
               
               let dateString =  usr.enddate;
               let enddate = new Date(dateString);
                      
               if(today > enddate)
               {
                this.error('User login expired');
                this.router.navigate(['/login']);
                return;
               }      
                
               
              //  if(usr.enddate>)
               if (usr!=undefined ) {
                                              
                  this.router.navigate(['/userDashboard']);
                 // this.currentUserSubject.next(usr);
               }
          }else
          {
              this.error('Invalid username and password');
              this.submitted = false;
              this.loading = false;
              //this.loading = false;
              if (this.loginForm.invalid) {
                return;
            }
          }
            },
            error => {
              alert("Sss" + error);
              this.router.navigate(['/userDashboard']);
                //this.alertService.error(error);
                this.loading = false;
            });
}
}

