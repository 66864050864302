import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  @Input() public name: string;
  @Input() public Description: string;
  @Input() public tileimgname: string;
  @Input() public url: string;
  @Input() public colortile: string;
  changeText:boolean=false; 
  constructor() { }

  ngOnInit() {
  }

}
