import { Component, OnInit } from '@angular/core';
//import edata1 from '../data/coursedata.json';
import edata from '../../assets/coursedata.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService  } from '@/app/services';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})

export class UserDashboardComponent implements OnInit {
  title = "Course Demo"
  masterSelected: boolean;
  checklist: any;
  checkliststyle: any;
  checklisttopicarea: any;
  checklistindustry: any;
  // checklistTechnology: any; 
  checkedList: any;
  dataorignal = edata;
  data = [];
  chklst = '';
  term: string;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.masterSelected = false;
    this.checklist = [
      { id: 1, value: 'e-learning', isSelected: false },
      { id: 2, value: 'HTML', isSelected: false },
      { id: 3, value: 'Microlearning', isSelected: false },
      { id: 2, value: 'Storyline', isSelected: false },
      { id: 40, value: 'Infographics', isSelected: false },
      { id: 29, value: 'RISE', isSelected: false },
      { id: 5, value: 'Video', isSelected: false },
      { id: 8, value: 'Virtual Reality', isSelected: false },
	    { id: 7, value: 'Animation', isSelected: false },
	    { id: 6, value: 'Augmented Reality', isSelected: false },
      { id: 40, value: 'ILT/ webinar', isSelected: false },
      { id: 40, value: 'Immersive learning', isSelected: false },
      { id: 4, value: 'Games/ Gamification', isSelected: false },
      { id: 39, value: 'Metaverse', isSelected: false },
      { id: 40, value: 'Powerpoint design', isSelected: false },
    ];
    
    this.checkliststyle = [
      { id: 9, value: 'Corporate', isSelected: false },
      { id: 10, value: 'Prezi', isSelected: false },
          { id: 11, value: 'Illustrative', isSelected: false },
          { id: 12, value: 'Parallax', isSelected: false },
          { id: 13, value: 'Scenario', isSelected: false },
          { id: 14, value: 'Photorealistic', isSelected: false },
          { id: 15, value: 'Character', isSelected: false }
       
         
    ]
    this.checklisttopicarea = [
      { id: 16, value: 'Product', isSelected: false },
      { id: 17, value: 'Brand', isSelected: false },
      { id: 18, value: 'Process', isSelected: false },
      { id: 19, value: 'Compliance/ Induction', isSelected: false },
      { id: 20, value: 'Systems/ Simulation', isSelected: false },
      { id: 21, value: 'Soft skills', isSelected: false },   
      { id: 22, value: 'Technical', isSelected: false },
      { id: 23, value: 'Sales', isSelected: false }
    ]
    this.checklistindustry = [
          { id: 24, value: 'Healthcare/ Hospitals', isSelected: false },
          { id: 25, value: 'Retail / Trade / FMCG', isSelected: false },
          { id: 26, value: 'BFSI', isSelected: false },
          { id: 27, value: 'Pharmaceuticals', isSelected: false },
          { id: 28, value: 'Manufacturing', isSelected: false },
          { id: 29, value: 'Media / Publishing', isSelected: false },
          { id: 30, value: 'Education', isSelected: false },
          { id: 31, value: 'E-commerce', isSelected: false }

    ]

//     this.checklistTechnology = [
//       { id: 25, value: 'Storyline', isSelected: false },
//       { id: 26, value: 'Captivate', isSelected: false },
//       { id: 27, value: 'BFSI', isSelected: false },
//       { id: 29, value: 'Pharmaceuticals', isSelected: false },
//       { id: 29, value: 'Manufacturing', isSelected: false },
//       { id: 32, value: 'Media / Publishing', isSelected: false },
//       { id: 33, value: 'Education', isSelected: false },
//       { id: 34, value: 'E-commerce', isSelected: false }

// ]
    this.data = this.dataorignal;
   }

  ngOnInit() {
  }
  

  isAllSelected() {
    // this.masterSelected = this.checklist.every(function (item: any) {
    //   return item.isSelected == true;
    // })

    // this.masterSelected = this.checkliststyle.every(function (item: any) {
    //   return item.isSelected == true;
    // })

    this.getCheckedItemList();

  }

  logout() {
    this.authenticationService.logout();
   //location.href = "http://localhost:4200/index.html";
   // location.href = "http://elearningdemos.com/coursedemo/index.html";
    // location.href = "http://elearningdemos.com/eapp/index.html";
    this.router.navigate(['/login']);
   // location.href = "http://elearningdemos.com/coursedemo/index.html";
}

  getCheckedItemList() {
    //debugger;
    this.checkedList = [];
    this.chklst = '';
    this.data = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
      {
        this.checkedList.push(this.checklist[i]);
        if(this.chklst=='')
        {
          this.chklst = this.checklist[i].value;
        }else
        {
          this.chklst = this.chklst + '#' + this.checklist[i].value ;
        }
      }
    }

    for (var i = 0; i < this.checkliststyle.length; i++) {
      if (this.checkliststyle[i].isSelected)
      {
        this.checkedList.push(this.checkliststyle[i]);
        if(this.chklst=='')
        {
          this.chklst = this.checkliststyle[i].value;
        }else
        {
          this.chklst = this.chklst + '#' + this.checkliststyle[i].value ;
        }
      }
    }

    for (var i = 0; i < this.checklisttopicarea.length; i++) {
      if (this.checklisttopicarea[i].isSelected)
      {
        this.checkedList.push(this.checkliststyle[i]);
        if(this.chklst=='')
        {
          this.chklst = this.checklisttopicarea[i].value;
        }else
        {
          this.chklst = this.chklst + '#' + this.checklisttopicarea[i].value ;
        }
      }
    }

    for (var i = 0; i < this.checklistindustry.length; i++) {
      if (this.checklistindustry[i].isSelected)
      {
        this.checkedList.push(this.checkliststyle[i]);
        if(this.chklst=='')
        {
          this.chklst = this.checklistindustry[i].value;
        }else
        {
          this.chklst = this.chklst + '#' + this.checklistindustry[i].value ;
        }
      }
    }
    
var status = 0;
    for (var prop in this.dataorignal) {
      if (this.dataorignal.hasOwnProperty(prop)) {
        var item = this.dataorignal[prop];
        var keywordsplit = item.keywords.toUpperCase().split('#');
        status = 0;
        var chk = this.chklst.split('#');
        for (var it in chk) {
          if (keywordsplit.indexOf(chk[it].toUpperCase()) > -1) {
            status++;
          }

        }

        if (status == chk.length) {
          this.data.push(item);
        }


      }
    }
    console.log(this.chklst);
    if(this.chklst.length==0)
    {
      this.data = this.dataorignal;
    }

    this.checkedList = JSON.stringify(this.checkedList);
    

  }
}
