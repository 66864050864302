 
      <div class="card text-dark bg-light">
         <div class="card-body ">
            <!-- <img src="../../assets/img/service_img1.png" class="img-fluid" alt=""/> -->
            <!-- <img src="assets/img/service_img1.png" class="img-fluid" alt=""/> -->
            <img src="assets/img/{{tileimgname}}" class="img-fluid" alt="course image"/>
         </div>
         <div class="card-footer {{colortile}}"> 
            <div class="row">
            <div class="col-md-10 col-sm-10 text-left text12">{{name}}</div>
            <div class="col-md-2 col-sm-2 text-left ml10" id="show" (mouseover)="changeText=true" (mouseout)="changeText=false"> <img src="assets/img/info.png"  /> <!--i class="fa fa-info-circle"  ></i--></div>
         </div></div>
      </div>
      <div class="col-md-12">
         <!-- <h5>{{name}}</h5> -->
         <div class="info-one"   [hidden]="!changeText" >
               <p> {{Description}}</p>
         </div>
        
      </div>
   