import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  baseUrl:string = "assets";
 public usr : any;
  constructor(private httpClient : HttpClient) { }

  get_login(){
     
  return this.httpClient.get(this.baseUrl + '/user.json');
   
            
    
 
 
}
 


}
